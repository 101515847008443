<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :dense="true" title="Resumen del Documento" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.purchases.retrieveLoader, text: `${(activeDocument || {}).name } Nº ${instance.number}`}" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn @click="downloadFile('purchases', `${instance.document_type}_N${instance.number}`, 'purchases', 'xml', instance.id)" :disabled="$store.state.purchases.retrieveLoader" outlined><v-icon left>mdi-download</v-icon>Descargar XML</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 424px;">
          <div style="position:fixed!important;">
            <v-img contain :width="424" :height="110" :src="require(`@/assets/backgrounds/background-document-detail.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 400px;">
                {{instance.document_type | nameDocumentsType}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="4" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Total</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.total | currency(instance.document_type)}}
                      <span class="caption">{{instance.document_type | firstDocumentsTypeCurrency}}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.created | date}}</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="424" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Resumen del documento</v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <div class="py-4 px-5">
                <v-list class="v-list-form-main transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-2" small color="secondary">mdi-account</v-icon>Razón Social</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{((instance || {}).supplier || {}).name}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-2" small color="secondary">mdi-card-bulleted</v-icon>{{$t('generals.RUT')}}</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{ ((instance || {}).supplier || {}).tax_id}}</v-list-item-subtitle>
                    </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-2" small color="secondary">mdi-file</v-icon>Tipo de documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.document_type | nameDocumentsType}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.status !== 'draft'">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-2" small color="secondary">mdi-file-document-multiple</v-icon>Folio</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.number}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-2" small color="secondary">mdi-card-bulleted</v-icon>ID</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-tooltip color="bgSearch" right transition="scale-transition" max-width="320">
                        <template v-slot:activator="{on}">
                          <v-btn class="fontDraw--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false" style="justify-content: left;">{{instance.id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="fontBody--text subtitle-2 d-flex align-center"><v-icon class="mr-2" small color="secondary">mdi-calendar-blank</v-icon>Creado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </div>
        </div>
        <!-- end summary -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">
          <!-- compliance -->
          <v-card flat class="mb-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-bold">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 secondary--text font-weight-semibold"><v-icon class="mr-2 mt-n1" small color="secondary">mdi-shield-check</v-icon>Cumplimiento
                    </span>
                  </v-col>
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="px-5 py-4">
              <v-skeleton-loader v-if="loadingSwap" class="rounded-md" type="list-item-three-line" />
              <v-list class="v-list-form-2 transparent pa-0" width="100%" v-else>
                <!-- <PermissionDenied v-if="!$helpers.hasPermission(['view_swapresponse', 'add_swapresponse'])" /> -->
                <template>
                  <v-list-item>
                    <v-list-item-title class="fontBody--text subtitle-2" v-if="swap.status">Respondido a</v-list-item-title>
                    <v-list-item-title class="fontBody--text subtitle-2" v-else>Responder a</v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="body-2 text--disabled font-italic" v-if="!swap.reply_to">Sin especificar</span>
                      {{swap.reply_to}}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <template>
                    <v-list-item>
                      <v-list-item-title class="fontBody--text subtitle-2">Estado</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip v-if="['PEN', null].includes(swap.status)" color="yellowLight" class="font-weight-semibold" small label>Pendiente de respuesta<v-icon right>mdi-clock</v-icon></v-chip>
                        <v-chip v-else-if="swap.status === 'ERM'" class="font-weight-semibold" color="lightBlue" text-color="primary" small label>Aceptado<v-icon right>mdi-check</v-icon></v-chip>
                        <v-chip v-else-if="swap.status === 'ACD'" class="font-weight-semibold"  color="lightBlue" text-color="primary" small label>Aceptado<v-icon right>mdi-check</v-icon></v-chip>
                        <v-chip v-else-if="['RFT', 'RFP', 'RCD'].includes(swap.status)" class="font-weight-semibold" color="redLight" small label>Rechazado<v-icon right>mdi-close</v-icon></v-chip>
                        <v-chip v-else-if="['NCA', 'ENC'].includes(swap.status)" class="font-weight-semibold" color="greyMedium" small label>Rechazado<v-icon right>mdi-file-remove</v-icon></v-chip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="!['PEN', null].includes(swap.status)">
                      <v-list-item>
                        <v-list-item-title class="fontBody--text subtitle-2">Detalle del estado</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="swap.status === 'ERM'">Otorga recibo de mercaderías o servicios</span>
                          <span v-else-if="swap.status === 'ACD'">Acepta contenido del documento</span>
                          <span v-else-if="swap.status === 'RCD'">Reclamo al contenido del documento</span>
                          <span v-else-if="swap.status === 'RFP'">Reclamo por falta parcial de mercaderías</span>
                          <span v-else-if="swap.status === 'RFT'">Reclamo por falta total de mercaderías</span>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <!-- <v-list-item>
                        <v-list-item-title class="fontBody--text subtitle-2">Archivos de respuesta</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          <v-btn class="mr-1" :href="swap.reception" target="_blank" v-if="swap.reception" outlined color="secondary" small>Recepción</v-btn>
                          <v-btn class="mr-1" :href="swap.receipt" target="_blank" v-if="swap.receipt" outlined color="secondary" small>Recepción</v-btn>
                          <v-btn class="mr-1" :href="swap.result" target="_blank" v-if="swap.result" outlined color="secondary" small>Resultado</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item> -->
                      <!-- <v-list-item>
                        <v-list-item-title class="fontBody--text subtitle-2">Respondido el</v-list-item-title>
                        <v-list-item-subtitle>{{swap.reply_date | dateTime}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title class="fontBody--text subtitle-2">Respondido por</v-list-item-title>
                        <v-list-item-subtitle>{{swap.reply_by}}</v-list-item-subtitle>
                      </v-list-item> -->
                    </template>
                    <v-list-item>
                    <v-list-item-title class="fontBody--text subtitle-2">Respuesta comercial</v-list-item-title>
                    <v-list-item-subtitle>
                      <chip-status v-if="!['PEN', null]?.includes(swap.status) && $helpers.hasPermission(['view_swapresponse'])" :status="swap?.status ?? {}" />
                      <template>
                        <v-hover v-slot="{ hover }">
                          <v-btn :class="[{'text-decoration-underline' : hover}, 'body-1']" @click="dialogSwap=true" color="primary" text :ripple="false">Aceptar o rechazar</v-btn>
                        </v-hover>
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  </template>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
          <!-- end compliance -->

          <!-- totals -->
          <v-expansion-panels v-model="panelTotals">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="pa-0">
                    <span class="ml-n1 subtitle-2 secondary--text font-weight-semibold"><v-icon class="mr-2 mt-n1" small color="secondary">mdi-currency-usd</v-icon>Detalle</span>
                  </v-col>
                  <v-col class="text-right mr-2 pa-0" v-if="panelTotals !== 0">
                    <span class="d-block secondary--text body-1 font-weight-medium">{{instance.total | currency(instance.document_type)}} {{instance.document_type | firstDocumentsTypeCurrency}}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0 pb-4">
                <v-list class="transparent pa-0">
                  <v-list-item class="px-5">
                    <v-list-item-title class="subtitle-2">Subtotal</v-list-item-title>
                    <v-list-item-subtitle class="text-right font-weight-bold">{{instance.subtotal | currency(instance.document_type)}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="px-5">
                    <v-list-item-title class="subtitle-2">Descuento</v-list-item-title>
                    <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">
                      <template v-if="instance.discount">
                        {{instance.discount | number}}
                        <template v-if="instance.discount_scheme === '%'">{{instance.discount_scheme}}</template>
                        <template v-if="instance.discount_scheme === '$'">{{instance.document_type | firstDocumentsTypeCurrency}}</template>
                      </template>
                      <template v-else>-</template>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <template v-if="(activeDocument || {}).has_taxes_detail">
                    <v-divider class="my-1" />
                    <v-list-item class="px-5">
                      <v-list-item-title class="subtitle-2">Exento</v-list-item-title>
                      <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">{{instance.exempt | currency(instance.document_type)}}</v-list-item-subtitle>
                    </v-list-item>
                    <template v-if="activeDocument.has_taxes">
                      <v-list-item class="px-5">
                        <v-list-item-title class="subtitle-2">Neto</v-list-item-title>
                        <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">{{instance.amount | currency(instance.document_type)}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-divider class="my-1" />
                      <v-list-item class="px-5">
                        <v-list-item-title class="subtitle-2">Impuestos ({{activeDocument.tax_percent}}%)</v-list-item-title>
                        <v-list-item-subtitle class="text-right fontBody--text font-weight-bold">{{instance.tax | currency(instance.document_type)}}</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </template>
                  <v-divider class="my-1" />
                  <v-list-item class="px-5">
                    <v-list-item-title class="body-1 fontBody--text font-weight-bold">Total documento</v-list-item-title>
                    <v-list-item-subtitle class="text-right body-1 fontBody--text font-weight-bold">{{instance.total | currency(instance.document_type)}} {{instance.document_type | firstDocumentsTypeCurrency}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end totals -->

          <!-- pdf -->
          <v-expansion-panels class="mt-5" v-model="panelPDF">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 secondary--text font-weight-semibold"><v-icon class="mr-2" small color="secondary">mdi-text-box</v-icon>Visualizar PDF</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0" style="min-height: 300px;">
                <render-pdf :documentType="instance.document_type | nameDocumentsType" :number="instance.number" :loaderRender="loaderRender" :renderUrl="renderUrl" :showToolbar="false" :flat="true" :formatPDF.sync="formatPDF" :showFormat="true"  />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end pdf -->

          <!-- related documents --
          <v-expansion-panels class="mt-5" v-model="panelRelatedDocuments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-4">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col>
                    <span class="subtitle-2 secondary--text font-weight-bold"><v-icon small left color="secondary">mdi-file-document-multiple</v-icon>Documentos relacionados</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-col class="text-center py-9">
                  <v-icon class="d-block" color="fontBody" size="48">mdi-file-document-remove</v-icon>
                  <span class="d-block body-1 font-weight-bold">No existen documentos relacionados</span>
                </v-col>
                <!- <v-sheet class="text-center pa-0" rounded v-else>
                  <documents-list :loading="loading" :show-pagination="false" />
                  <v-divider />
                  <v-btn class="my-2" :to="{name: 'DocumentsList'}" outlined>Ver todos los documentos</v-btn>
                </v-sheet> --
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!- end related documents -->

          <!-- comments --
          <v-expansion-panels class="mt-5" v-model="panelComments">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-3">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col>
                    <span class="subtitle-2 secondary--text font-weight-bold"><v-icon small left color="secondary">mdi-email</v-icon>Comentarios</span>
                  </v-col>
                  <v-col class="text-right">
                    <v-icon size="18" left :color="comments ? 'primary' : 'secondary'">mdi-message-badge</v-icon>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-container>
                  <v-row class="px-5 py-5" align="start">
                    <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                    <v-col class="py-0 pr-0">
                      <v-textarea placeholder="Escribe un comentario..." auto-grow outlined flat hide-details />
                    </v-col>
                  </v-row>
                 <v-list class="pa-0" three-line v-if="comments">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="require(`@/assets/user-${ $vuetify.theme.dark ? 'dark' : 'light'}.svg`)" :width="30" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="subtitle-1 font-weight-bold secondary--text">Juan Pérez</v-list-item-title>
                      <span class="d-block secondary--text caption">Hace 3 horas</span>
                      <v-list-item-subtitle class="secondary--text mt-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames libero integer potenti at in nec. Urna turpis massa est ultrices faucibus diam et donec vitae.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                 </v-list>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!- end comments -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>

    <!-- dialog swap update -->
    <v-dialog v-model="dialogSwap" width="410" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Aceptar o rechazar {{((instance || {}).document || {}).name}}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSwap=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-8" align="center" justify="center">
            <img :src="require(`@/assets/backgrounds/modal-purchase-null.svg`)" :width="168" />
          </v-row>
          <v-col class="pt-8 px-0 pb-0">
            <v-list class="pa-0 transparent" three-line>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>Selecciona la respuesta a los documentos.</v-list-item-title>
                  <v-list-item-subtitle class="pt-2">
                    <v-autocomplete v-model="swapResponse.status" :items="swapStatuses" item-text="label" item-value="value" single-line hide-details dense return-object outlined />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogSwap=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="dialogSwap=false, dialogConfirmSwap=true" color="primary">Responder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog swap update -->

    <!-- dialog confirm swap update -->
    <v-dialog v-model="dialogConfirmSwap" width="838" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Aceptar o rechazar</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirmSwap=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 body-1 pl-6">
              Se registrará el evento <span class="font-weight-bold text-lowercase">{{swapResponse.status.label || swapStatuses[0].label }}</span> en el registro de compras del {{$t('generals.SII')}}, y se le enviará, una notificación al proveedor informando la respuesta del documento.

              <span class="d-block body-1 pt-3">Al confirmar esta acción, no habrá vuelta atrás.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />

              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-4" :height="50" dense text>
                <div class="d-flex pa-0 my-1 pt-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2" style="line-height: 14px;">Esta acción
                      <span class="font-weight-bold">podría tardar varios minutos</span> en completarse.
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirmSwap=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogSwap=false, updateSwap()" :loading="$store.state.irs.createLoader" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm swap update -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import purchasesList from '@/data/purchasesList' // example
  import MiniHeader from '@/components/commons/MiniHeader'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import DocumentsList from '@/modules/documents/components/DocumentsList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import DocumentTypes from '@/collections/documentTypes'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      ChipStatus,
      MiniHeader,
      VModal,
      SkeletonDetail,
      RenderPdf,
      DocumentsList,
      PermissionDenied
    },
    data: () => ({
      instance: {},
      formatPDF: 1,
      scroll: 0,
      confirm: '',
      comments: '',
      dialogSwap: false,
      dialogConfirmSwap: false,
      loading: false,
      loadingSwap: false,
      // offsetTop: 0,
      panelIrsSii: [],
      panelComments: false,
      panelEmail: false,
      panelPDF: false,
      panelRelatedDocuments: false,
      panelTotals: false,
      swap: {},
      swapResponse: {
        status: 'ERM'
      },
      swapStatuses: [
        { value: 'ERM', label: 'Otorga recibo de mercaderías o servicios' },
        { value: 'ACD', label: 'Acepta contenido del documento' },
        { value: 'RCD', label: 'Reclamo al contenido del documento' },
        { value: 'RFP', label: 'Reclamo por falta parcial de mercaderías' },
        { value: 'RFT', label: 'Reclamo por falta total de mercaderías' }
      ],
      env: process.env?.VUE_APP_MODE ?? 'ax',
      breadcrumbs: {
        main: 'Gestión documental',
        children: []
      }
    }),
    computed: {
      ...mapState({
        // instance: state => state.purchases.purchasesDetail
      }),
      activeDocument () {
        return DocumentTypes.find(d => d.id === this.instance.document_type)
      }
    },
    watch: {
      panelPDF (val) {
        this.modalRender = val
        if (val === 0) {
          this.$vuetify.goTo(0, { duration: '0', easing: 'linear' })
          if (!this.renderUrl) this.renderDocument('purchases')
        }
        this.formatPDF = 1
      },
      formatPDF: {
        handler (val) {
          if (val === 1) this.renderDocument('documents')
          else {
            this.renderDocument('purchases', 'purchases', 'pdf', this.instance.id, { thermal_format: val})
          }
        },
        inmediate: true,
        deep: true
      }
    },
    created () {
      this.instance = purchasesList.find(({id}) => id === this.$route.params.id)
      // this.$store.dispatch('purchases/RETRIEVE', {
      //   resource: 'purchases',
      //   id: this.$route.params.id,
      //   query: {
      //     expand: 'supplier'
      //   }
      // })
      // .then(() => {
      //   this.loadingSwap = true
      //   if (this.$helpers.hasPermission(['view_swapresponse'])) {
      //     this.$store.dispatch('irs/LIST', {
      //       resource: 'irs/sii/swaps',
      //       query: {
      //         purchases__in: this.$route.params.id
      //       }
      //     })
      //     .then((response) => {
      //       if (response.data.results) {
              this.swap = {
                id: "swa_KWL0n23Hu99bO4ikOQ",
                imported: false,
                reply_to: "paula.racamonte@intercorp.com",
                reply_date: null,
                status: "ERM",
                status_detail: null,
                created: "2023-06-27T14:15:59.217304-04:00",
                account: "acc_frXwm3MlKGBQDmT1I6",
                reply_by: null,
                purchases: [
                    "pur_9j7D6wGvW48JAJXOaW"
                ]
              }
      //       }
      //     })
      //     .finally(() => {
      //       this.loadingSwap = false
      //     })
      //   }
      // })
    },
    methods: {
      onScroll (e) {
        this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
      },
      async handlePanel () {
        await this.$root.$emit('isClose', true)
        this.$router.replace({ name: 'DocumentsList'})
      },
      updateSwap () {
        this.$store.dispatch('irs/UPDATE', {
          resource: 'irs/sii/swaps',
          id: this.swap.id,
          payload: {
            status: this.swapResponse.status.value,
            purchase: this.instance.id
          }
        })
        .then((response) => {
          this.swap = response.data
          this.modalSwap = false
          this.confirm = ''
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      }
    }
  }
</script>